import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('@/views/index'),
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/privacy'),
    },
    {
      path: '/agree',
      name: 'agree',
      component: () => import('@/views/agree'),
    },
    {
      path: '/marketing',
      name: 'marketing',
      component: () => import('@/views/marketing'),
    },
    {
      path: '/404',
      component: () => import('@/views/404'),
      hidden: true,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

export default router;
