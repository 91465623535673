<template>
  <div light class="container" id="app">
    <header-layout v-if="isHeader"></header-layout>
    <router-view />
    <footer-layout></footer-layout>
  </div>
</template>

<script>
import HeaderLayout from './components/header.vue';
import FooterLayout from './components/footer.vue';
export default {
  name: 'App',
  components: { HeaderLayout, FooterLayout },
  data() {
    return {
      isHeader: true,
    };
  },
  created() {
    this.$watch(
      () => this.$route,
      () => {
        this.isHeader = this.$route.query.isHeader == '0' ? false : true;
      }
    );
  },
};
</script>

<style lang="scss">
@import '../styles/index.css';
</style>
