<template>
  <div class="full-body header" flex fic fjc>
    <div class="w8 mw11 logo-layout" flex fic fjs>
      <a href="/"> <img src="../assets/upicklogo.png" alt="" /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderLayout',
};
</script>

<style lang="scss">
@import './styles/module/mixin.device.scss';
.header {
  height: 60px;

  .logo-layout {
    height: 70px;
    min-height: 70px;
    max-height: 70px;

    a {
      height: 100%;

      img {
        height: 100%;
      }
    }
    @include device-mobile() {
      justify-content: center !important;
    }
  }
}
</style>
